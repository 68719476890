import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import logo from "../images/header-116x100.png"
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { Menu, Close } from '@material-ui/icons';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(theme => ({
  header: {
    background: `#00295D`,
    marginBottom: `1.45rem`,
    boxShadow: theme.shadows[1],
  },
  navbar: {
    width: "95%",
    maxWidth: "1000px",
    padding: `0.5rem 1.0875rem`,
    margin: `0 auto`,
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,

  },
  item: {
    color: `white`,
    textDecoration: `none`,
    // textTransform: `uppercase`,
    fontSize: "1.1rem"
  },
  burger: {
    color: `white`,
    fontSize: "2rem"
  },
  paper: {
    position: 'absolute',
    width: "90%",
    height: "90%",
    margin: "5%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2),
    display: "flex",
    flexDirection: "column"


  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    "& svg": {
      color: "#00295D",
      fontSize: "3rem"
    }
  },
  modalmenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100%"
  },
  modalitem: {
    color: "#00295D",
    textDecoration: `none`,
    fontSize: "1.5rem",
    marginBottom: theme.spacing(2),
  },
  logo: {
    maxHeight: theme.spacing(7)
  }
}));


const menu = [
  { label: "Programma", path: "/" },
  { label: "Feest", path: "/feest" },
  { label: "Pubquiz", path: "/pubquiz" },
  { label: "Gala", path: "/gala" },
  { label: "Reis", path: "/reis" },
  { label: "VaZo", path: "/vazo" },
]

const MenuModal = ({ open, setOpen }) => {
  const classes = useStyles()
  return (<Hidden smUp><Modal
    // className={classes.modal}
    open={open}
    onClose={() => setOpen(false)}
    closeAfterTransition

    BackdropProps={{
      timeout: 500,
    }}
  >
    <div className={classes.paper}>
      <div className={classes.close}><Close onClick={() => setOpen(false)} /></div>
      <div className={classes.modalmenu}>
        {menu.map(i => <Link
          to={i.path}
          onClick={() => setOpen(false)}
          className={classes.modalitem}
        >
          {i.label}
        </Link>)}
      </div>
    </div>

  </Modal></Hidden>)
}


const Header = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles()
  return <>
    <header
      className={classes.header}
    >
      <div className={classes.navbar}    >
        <Link
          to="/"
          className={classes.item}
        >
          <img src={logo} alt="Logo" className={classes.logo} />
        </Link>
        <Hidden xsDown>
          {menu.map(i => <Link
            to={i.path}
            className={classes.item}
          >
            {i.label}
          </Link>
          )}
        </Hidden>

        <Hidden smUp>  <Menu onClick={() => setOpen(true)} className={classes.burger} /></Hidden>
      </div>


    </header >
    <MenuModal open={open} setOpen={setOpen} />
  </>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
