import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import "./layout.css";
import Header from "./header"
import { Helmet } from "react-helmet"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {
    width: "95%",
    maxWidth: "900px",
    margin: "2rem auto",
    textAlign: "justify"
  },
  root: {
    flexGrow: 1,
  }
})

const Layout = ({ children }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider>
      <CssBaseline />
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={classes.main}>
        <div className={classes.root}>{children}</div></main>
      <footer>
      </footer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
